export class VoucherModel {
    public Id: string;
    public ExternalId: string;
    public VoucherType: string;
    public ServicePlan: string;
    public Status;
    public Series: string;
    public Price: string;
    public Prefix: string;
    public ShortLogin: string;
    public Login: string;
    public FullLogin: string;
    public Password: string;
    public ExpiredBy: string;
    public ValidTill: Date;
    public TimeExpired: string;
    public TransactionNumber: string;
    public SoldBy: string;
    public ActivationDate: Date;
    public CustomerName: string;
    public CustomerId: string;
    public DownloadTraffic: number;
    public UploadTraffic: number;
    public TrafficTotal: number;
    public Locations: string;
}

export enum VoucherExportFieldName {
    ID = 'ID',
    Series = 'Series',
    Status = 'Status',
    Login = 'Login',
    Password = 'Password',
    Speed = 'Speed (in/out)',
    OnlineTime = 'Online time',
    Location = 'Location',
    Download = 'Download',
    Upload = 'Upload',
    Traffic = 'Traffic',
    ValidTill = 'Valid till',
    AvailableTimeAfterActivation = 'Available time from voucher activation',
    Type = 'type',
    Serie = 'serie',
    Price = 'price',
    Prefix = 'prefix',
    Short_Login = 'short_login',
    Expired_By = 'expired_by',
    ExpirationBy = 'Expiration by',
    Valid_Till = 'valid_till',
    Time_Expired = 'time_expired'
}
