
import { Observable, of } from 'rxjs';
import { retryWhen } from 'rxjs/operators';
import { mergeMap, delay, take, concat } from 'rxjs/operators';

const retryDelay: number = 8000;

export function retryRequest(): (source$: Observable<any>) => Observable<any> {
  return source$ =>
    source$.pipe(
        retryWhen(error => {
            return error
               .pipe(mergeMap((errorStatus: any) => {
                 console.log(`retrying request: ${errorStatus.status}`);
                  if (errorStatus.status  === 503 || errorStatus.status  === 0) {
                    return of(errorStatus.status)
                            .pipe(delay(retryDelay));
                  }
                  return Observable.throw(errorStatus);
               }))
               .pipe(take(2))
               .pipe(concat(Observable.throw({
                   error: 'Sorry, there was an error (after multiple retries)'
                })));
        }));
    }
