import { Injectable } from '@angular/core';
import { HttpClient,
         HttpHeaders,
         HttpParams,
         HttpErrorResponse } from '@angular/common/http';

import * as CryptoJS from 'crypto-js';
import { Observable } from 'rxjs';
import { AppConfig } from '../app.config';
import { environment } from '../../environments/environment';
import { BaoConstants } from '../enums/index';

import { BooleanValueDataResponseModel,
         AuthenticationResult,
         AuthenticationResultDataResponse,
         LoginModel,
         UserTokenModel } from '../models';

import * as moment from 'moment';
import { retryRequest } from '../pipes/retry-request.operator';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthenticationService {

    private actionUrl: string =  '/Authentication';
    private salt: any  = CryptoJS.enc.Utf8.parse('1ed6bef9fd72406b');
    private httpOptions = {
              headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Skip-Interceptor': ''
              })
            };

    constructor(private appConfig: AppConfig, private http: HttpClient) {}

    // "/oauth2/token"
    public ValidateLogin(loginModel: LoginModel): Observable<any> {

        const options = {
            headers: new HttpHeaders({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-Skip-Interceptor': ''
            })
          };
        
       return this.http.post<AuthenticationResultDataResponse>( `${this.actionUrl}/ValidateLogin`,
                                                                loginModel,
                                                                options)
                       .pipe(retryRequest());
    }

    // "/oauth2/otactoken"
    //** /api/Authentication/CompletePasswordLessLogin/{code}
    //
    public ValidateLoginByAuthorizationCode(loginModel: LoginModel): Observable<AuthenticationResultDataResponse> {
/*
        const encryptedpassword = this.encrypt(loginModel.password, this.salt);

        const body = new HttpParams()
        .set('username', loginModel.username)
        .set('password', encryptedpassword)
        .set('client_id', environment.clientId)
        .set('keepme_signedin', loginModel.keepMeSignedIn.toString())
        .set('grant_type', 'password')
        .set('connection', 'sms');
*/
        const url = `${this.actionUrl}/CompletePasswordLessLogin/${loginModel.password}/`;

        const options = {
            headers: new HttpHeaders({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-Skip-Interceptor': ''
            })
          };

        return this.http.post<AuthenticationResultDataResponse>( url,
                                                                 '',
                                                                 options)
                        .pipe(retryRequest());
    }

    // "/oauth2/token"
    public RefreshAccessToken(): Observable<AuthenticationResultDataResponse> {

        const options = {
            headers: new HttpHeaders({
              'Accept': 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded',
              'X-Skip-Interceptor': ''
            })
          };

        const token = localStorage.getItem(BaoConstants.JWT_TOKEN);

        if (null === token) {
            return Observable.throw('missing token');
        }

        const userToken = JSON.parse(token);

        if (null === userToken) {
            return Observable.throw('invalid user token');
        }

        userToken.decodedJwt = this.getDecodedAccessToken(userToken.jwt);

        const body = new HttpParams()
        .set('username', userToken.decodedJwt.sub)
        .set('client_id',  environment.clientId)
        .set('refresh_token', userToken.refreshToken)
        .set('keepme_signedin', userToken.keepMeSignedIn.toString())
        .set('grant_type', 'refresh_token');

        return this.http.post<AuthenticationResultDataResponse>( `${this.actionUrl}`,
                                                                    body.toString(),
                                                                    options)
                        .pipe(retryRequest());
    }

    // 
    //** /api/Authentication/StartPasswordLessLogin/{mobile}
    //
    public SendAuthorizationCode(mobileNumber: string): Observable<BooleanValueDataResponseModel>  {
        const url = `${this.actionUrl}/StartPasswordLessLogin/${mobileNumber}/`;

        const options = {
            headers: new HttpHeaders({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-Skip-Interceptor': ''
            })
          };

        return this.http.post<AuthenticationResultDataResponse>( url,
                                                                '',
                                                                options)
                        .pipe(retryRequest());
    }

    public Logout() {
        //
        // To log out, we just need to remove the user's profile and token
        // then redirect user back to login page
        //
        localStorage.removeItem(BaoConstants.JWT_TOKEN);
        localStorage.removeItem(BaoConstants.USER_MODEL);
        localStorage.clear();
    }

    public GetJwt(): string {
        const userToken = this.GetUserToken();

        if (null == userToken) {
            return '';
        }

        return userToken.jwt;
    }

    public GetTokenSignature(): string {
        const userToken = this.GetUserToken();

        userToken

        const token = userToken.jwt.split('.', 3);
        return token[2];
    }

    public IsAuthenticated(): boolean {

        const token = this.GetUserToken();

        if (true == this.tokenIsNullOrEmpty(token)) {
            return false;
        }

        return false == this.isTokenExpired(token.jwt);
    }

    public HasRole(role: string): boolean {
        let result: boolean = false;

        if (null == role || '' === role) {
            return true;
        }

        // decode the token to get its payload
        const tokenPayload = this.getDecodedAccessToken(localStorage.getItem('id_token'));
        const token = this.GetUserToken();

        if (true == this.tokenIsNullOrEmpty(token)) {
            return false;
        }

        if (null == token.decodedJwt.roles) {
            //
            // user is not assigned any roles, log them out and redirect them to login page
            //
            this.Logout();
            document.location.href =  environment.baseURL;
            return false;
        }

        for (const userRole of token.decodedJwt.roles) {
            if (userRole.toLowerCase() === role.toLocaleLowerCase()) {
                result = true;
            }
        }

        return result;
    }

    public KeepUserSignedIn(): boolean {

        const token = this.GetUserToken();

        if (true == this.tokenIsNullOrEmpty(token)) {
            return false;
        }

        const keepMeSignedIn: boolean = token.keepMeSignedIn;
        return keepMeSignedIn;
    }

    private encrypt(message, salt): string {
         //
         // encrypt the Password with Base64
         //
         const key = CryptoJS.enc.Utf8.parse('b36736d9810cf998');

         const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(message),
                                                key,
                                                {
                                                    keySize: 128 / 8,
                                                    iv: salt,
                                                    mode: CryptoJS.mode.CBC,
                                                    padding: CryptoJS.pad.Pkcs7
                                                });

        return this.base64EncodeUrl(encrypted.toString());
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return Observable.throw(error);
      }

    ///
    /// use this to make a Base64 encoded string URL friendly,
    /// i.e. '+' and '/' are replaced with '-' and '_' also any trailing '='
    /// characters are removed
    ///
    /// @param {String} str the encoded string
    /// @returns {String} the URL friendly encoded String
    ///
    private base64EncodeUrl(str) {
        return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
    }

    public SaveUserToken(authResult: AuthenticationResult, rememberMe: boolean): Observable<boolean> {

        const saveTokenObservable = new Observable<boolean>((observer) => {

            const userToken: UserTokenModel = {
                decodedJwt: '',
                jwt: authResult.token,
                refreshToken: authResult.refreshToken,
                keepMeSignedIn: rememberMe,
                issuedUtc: authResult.issuedUtc,
                expiresUtc: authResult.expiresUtc
            };

            const jsonToken = JSON.stringify(userToken);
            localStorage.setItem(BaoConstants.JWT_TOKEN, jsonToken);

            observer.next(true);
            observer.complete();
        });

        return saveTokenObservable;
    }

    public GetUserToken(): UserTokenModel {
        let userToken: UserTokenModel = null;
        let token: string = '';

        token = localStorage.getItem(BaoConstants.JWT_TOKEN);

        if (null == token || '' === token) {
            return null;
        }

        userToken = JSON.parse(token);

        const theDate = new Date();
        const utcDate = theDate.getUTCDate();

        if (moment(utcDate).isAfter(moment(userToken.expiresUtc))) {
            //
            // token has expired
            //
            localStorage.removeItem(BaoConstants.JWT_TOKEN);
            console.log('token expired');
            return null;
        }

        userToken.decodedJwt = this.getDecodedAccessToken(userToken.jwt);
        return userToken;
    }

    getDecodedAccessToken(token: string): any {
        try {
            const jwtHelper = new JwtHelperService();

            return jwtHelper.decodeToken(token);
        } catch (Error) {
            return null;
        }
    }

    isTokenExpired(token: string): boolean {
        try {
            const jwtHelper = new JwtHelperService();
            const expirationDate = jwtHelper.getTokenExpirationDate(token);

            return jwtHelper.isTokenExpired(token);
        } catch (Error) {
            return true;
        }
    }

    tokenIsNullOrEmpty(token: any): boolean {

        if (   null == token
            || null == token.jwt 
            || '' === token.jwt) {
            return true;
        }

        return false;
    }
}
