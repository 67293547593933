
export enum BaoPackageType {
    BaoHappyHourUnlimited = 'BaoHappyHourUnlimited',
    Bao1Unlimited = 'Bao1Unlimited',
    Bao7Unlimited = 'Bao7Unlimited',
    Bao30Unlimited = 'Bao30Unlimited',
}

export const BaoPackageDescription = new Map<string, string>([
    [BaoPackageType.BaoHappyHourUnlimited, 'Bao Happy Hour unlimited internet service'],
    [BaoPackageType.Bao1Unlimited, 'Bao 24 hours unlimited internet service'],
    [BaoPackageType.Bao7Unlimited, 'Bao 7 days unlimited internet service'],
    [BaoPackageType.Bao30Unlimited, 'Bao 30 days unlimited internet service']
  ]);
