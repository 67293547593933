export class LoginResultDataResponseModel {
    Result: string;
    isSuccess: boolean;
    affectedRecords: number;
    endUserMessage: string;
    validationErrors: [{
                        Name: string;
                        Description: string;
                      }];
    result: {
        loginResult: number,
        token: string
    }
}