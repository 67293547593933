import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

const MINUTES_UNITL_AUTO_LOGOUT = 30; // in mins
const CHECK_INTERVAL = 60000; // in ms
const STORE_KEY =  'lastAction';

@Injectable()

export class AutoLogoutService {


  constructor(private auth: AuthenticationService) {

    this.reset();
    this.initInterval();
    this.initListener();
  }

  get lastAction() {
    return parseInt(localStorage.getItem(STORE_KEY), 10);
  }

  set lastAction(value) {
    localStorage.setItem(STORE_KEY, String(value));
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
  }

  reset() {
    this.lastAction = Date.now();
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout && this.auth.IsAuthenticated()) {
      this.auth.Logout();

      //
      // return user to login page
      //
      document.location.href =  environment.baseURL;
    }
  }
}
