import { TransactionCategory, TransactionStatus } from './';

export class BuyNowTransactionModel {
    public id: string;
    public createDate: Date;
    public createdBy: string;
    public updatedBy: string;
    public updateDate: Date;
    public servicePlan: string;
    public mobile: string;
    public email: string;
    public fullname: string;
    public discountCode: string;
    public shortTransactionNumber: string;
    public amount: number;
    public transactionId: string;
    public transactionStatus: TransactionStatus;
    public transactionCategory: TransactionCategory;
    public initiatorMSISDN: string;
    public voucherId: string;
    public voucherLogin: string;
}