import { TransactionModel } from './TransactionModel';
import { ValidationItemModel } from './index';

export class TransactionModelDataResponse {
    isSuccess: boolean;
    affectedRecords: number;
    endUserMessage: string;
    validationErrors: ValidationItemModel [];
    result: TransactionModel;
}
