export enum VerifyEmailResult {
    Failed = 'Failed',
    Succeeded = 'Succeeded',
    CodeExpired = 'CodeExpired',
    BadResetCode = 'BadResetCode'
  }
  
  export class VerifyEmailResultModel {
      Result: VerifyEmailResult;
      isSuccess: boolean;
      affectedRecords: number;
      endUserMessage: string;
      validationErrors: [{
                          Name: string;
                          Description: string;
                        }];
  }
  