import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()

export class AuthenticationGuard {

  constructor( private authService: AuthenticationService, private router: Router ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
    //
    // this will be passed from the route config on the data property
    // is user authenticated
    //
    if (false === this.authService.IsAuthenticated()) {
          this.router.navigate(['/user/login']);
          return false;
    }

    //
    // does route expect any roles
    //
    if (null == route.data['expectedRoles']) {
      return true;
    }

    const expectedRoles = route.data['expectedRoles'] as Array<string>;
    let hasExpectedRole: boolean = false;

    //
    // check if user has any expected role
    //
    for (let role of expectedRoles) {
      if (true === this.authService.HasRole(role) ) {
          hasExpectedRole = true;
        }
    }

    if (state.url.indexOf('app/dashboard') > 0 ) {

        //
        // direct user to correct dashboard based on role
        //
        if (this.authService.HasRole('Administrator')
            && state.url !== '/app/dashboard/admin') {

          this.router.navigate(['/app/dashboard/admin']);
          return false;
        }
        else if (this.authService.HasRole('Manager')
                 && state.url !== '/app/dashboard/manager') {

          this.router.navigate(['/app/dashboard/manager']);
          return false;
        }
        else if (this.authService.HasRole('Dealer')
                 && state.url !== '/app/dashboard/dealer') {

          this.router.navigate(['/app/dashboard/dealer']);
          return false;
        }
    }

    return true;
  }
}
