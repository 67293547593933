import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { CarouselSlideModel } from '../models/CarouselSlideModel';

import {
  ICarouselConfig,
  AnimationConfig
} from './declarations';

@Injectable()
export class CarouselService {
  private imageLoadedCount = 0;

  private imageLoad: Subject<CarouselSlideModel>;
  private config: ICarouselConfig;

  constructor() {}

  public init(imageSources: CarouselSlideModel[], config: ICarouselConfig) {
    this.imageLoadedCount = 0;
    this.imageLoad = new Subject<CarouselSlideModel>();

    config.autoplayDelay = config.autoplayDelay < 1000 ? 1000 : config.autoplayDelay;

    this.config = config;

    if (!this.config.verifyBeforeLoad) {
      return;
    }

    this.loadImages(imageSources);
  }

  public onImageLoad(): Observable<CarouselSlideModel> {
    return this.imageLoad.asObservable();
  }

  public getConfig(): ICarouselConfig {
    return Object.assign({}, this.config);
  }

  private loadImages(imageSources: CarouselSlideModel[]): void {
    const createImageElement = (carouselSlideModel: CarouselSlideModel): void => {
      const imgElement = document.createElement('img');
      imgElement.src = carouselSlideModel.ImageUrl;

      imgElement.onload = this.onImageElementLoad.bind(this, imageSources, carouselSlideModel);

      imgElement.onerror = this.onImageElementLoadError.bind(this, imageSources, carouselSlideModel);
    };

    imageSources.forEach(createImageElement);
  }

  private onImageElementLoad(imageSources: CarouselSlideModel[], image: CarouselSlideModel): any {
    this.imageLoadedCount++;
    this.imageLoad.next(image);

    this.carouselTinyLogger(image.ImageUrl, true);

    this.emitIfAllImagesLoaded(imageSources);
  }

  private onImageElementLoadError(imageSources: CarouselSlideModel[], image: CarouselSlideModel): any {

    var index = this.indexOfCarouselSlide(imageSources, image.ImageUrl);

    if(index > - 1){
      imageSources.splice(index, 1);
      this.carouselTinyLogger(image.ImageUrl, false);
      this.emitIfAllImagesLoaded(imageSources);
    }
  }

  private indexOfCarouselSlide(imageSources: CarouselSlideModel[], imageUrl: string) : number {
    var index = -1;

    for (var i = 0; i < imageSources.length; i++) { 
      if(imageUrl == imageSources[i].ImageUrl){
          index = i;
          break;
        }
    } 

    return index;
  }

  private emitIfAllImagesLoaded(imageSources: CarouselSlideModel[]) {
    if (this.imageLoadedCount === imageSources.length) {
      this.imageLoad.complete();
    }
  }

  private carouselTinyLogger(image: string, isLoaded: boolean): void {
    if (!this.config.log) {
      return;
    }

    if (isLoaded) {
      console.log(`Carousel module: image loaded: ${image}`);
      return;
    }

    console.error(`Carousel module: image load error: ${image}`);
  }
}

