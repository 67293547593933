import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CommandModel } from '../models';
import { catchError, retry, retryWhen, delay, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class CommandService {
    private actionUrl: string;
    private headers: HttpHeaders;

    constructor(private http: HttpClient) {

        this.actionUrl = '/Command/';
        this.headers = new HttpHeaders();
        this.headers.append('Accept', 'application/json');
        this.headers.append('Content-Type', 'application/json');
    }

    public Execute = (command: CommandModel): Observable<CommandModel> => {
        const url = `${this.actionUrl}Execute`;

        return this.http.post<CommandModel>(    url,
                                                command,
                                                { headers: this.headers }
                                            ).pipe(
                                            retryWhen( errors =>
                                                       errors
                                                       .pipe(delay(500))
                                                       .pipe(take(2))),
                                            catchError(this.handleError)
                                           );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return Observable.throw(error);
      }
}
