export enum TransactionType {
    AccountCredit = 'Credit',
    AccountDebit = 'Debit',
    AdjustmentCredit = 'AdjustmentCredit',
    AdjustmentDebit = 'AdjustmentDebit'
}

export enum TransactionCategory {
    AccountRefill = 'AccountRefill',
    AccountCredit = 'AccountCredit',
    AccountDebit = 'AccountDebit',
    VoucherRedeem = 'VoucherRedeem',
    SubscriptionActivation = 'SubscriptionActivation',
    SubscriptionExtension = 'SubscriptionExtension',
    MobileWalletCredit = 'MobileWalletCredit',
    MobileWalletPurchase = 'MobileWalletPurchase',
    CreditLineIncrease = 'CreditLineIncrease',
    CreditTransfer = 'CreditTransfer',
    VoucherSell = 'VoucherSell',
    VoucherPurchase = 'VoucherPurchase',
    VoucherPurchaseBuyNow = 'VoucherPurchaseBuyNow',
    AdjustmentCredit = 'AdjustmentCredit',
    AdjustmentDebit = 'AdjustmentDebit'
}

export enum TransactionStatus {
    Created = 'Created',
    Complete = 'Complete',
    InsufficientFunds = 'InsufficientFunds',
    PaymentPending = 'PaymentPending',
    Expired = 'Expired'
}

export class TransactionModel {
    public id: string;
    public transactionNumber: string;
    public referenceNumber: string;
    public transactionStatus: TransactionStatus;
    public amount: number;
    public invoiceNumber: string;
    public transactionType: TransactionType;
    public transactionCategory: TransactionCategory;
    public accountBalance: number;
    public accountNumber: string;
    public username: string;
    public firstname: string;
    public lastname: string;
    public staffLastname: string;
    public staffFirstname: string;
    public staffUsername: string;
    public voidedBy: string;
    public voidDate: Date;
    public startDate: Date;
    public endDate: Date;
    public createDate: Date;
    public createdBy: string;
    public updateDate: Date;
    public updatedBy: string;
    public voucherId: string;
    public externalReferenceNumber: string;
    public externalTransactionNumber: string;
    public paymentMethod: string;
    public voucherLogin: string;
    public voucherShortLogin: string;
    public voucherMobileNumber: string;
    public voucherEmailAddress: string;
    public voucherServicePlan: string;
    public mobile: string;
    public client: any;
    public dealer: any;
    public transactionDetails: any;
    public voucherDetails: any;
    public paymentDetails: any;
}
