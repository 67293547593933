
export class SearchUserDataResponseModel {
    isSuccess: boolean;
    affectedRecords: number;
    endUserMessage: string;
    validationErrors: [{
                        Name: string;
                        Description: string;
                      }];
    result: SearchUserModel;
}

export class SearchUserModel {
    public id: string;
    public username: string;
    public firstname: string;
    public lastname: string;
    public mobilePhone: string;
    public email: string;
}
