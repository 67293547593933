import { TransactionModel } from './TransactionModel';

export class AccountModel {
   accountNumber: string;
   currentBalance: number;
   availableBalance: number;
   availableCredit: number;
   creditLine: number;
   creditLimit: number;
   lastRefillDate: Date;
   lastVoucher: string;
   isRefilled: boolean;
   lastRefillAmount: number;
   lastRefillMethod: string;
   transactions: TransactionModel [];
}

export enum PaymentMethod {
   AccountBalance = 'AccountBalance',
   BillMeLater = 'BillMeLater',
   Cash = 'Cash'
}
