import { ValidationItemModel } from './ValidationItemModel';

export class PageDataResponseModel {
    isSuccess: boolean;
    affectedRecords: number;
    endUserMessage: string;
    validationErrors: [ValidationItemModel];
    currentPage: Number;
    pageSize: Number;
    totalRecords: Number;
    listResult: Array<any>;
}