export class CommandModel {
    public Path: string;
    public CommandType: string;
    public Payload: any;
    public ActualClientRoute: string;

    constructor(commandType: string,
                path: string,
                payload: any,
                actualClientRoute: string) {

       this.CommandType = commandType;
       this.Path = path;
       this.Payload = payload;
       this.ActualClientRoute = actualClientRoute;
   }
}
