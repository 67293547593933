import { AccountModel } from './AccountModel';
import { CarouselSlideModel } from './CarouselSlideModel';

export class AccountModelDataResponse {
    isSuccess: boolean;
    affectedRecords: number;
    endUserMessage: string;
    validationErrors: [{
                        Name: string;
                        Description: string;
                      }];
    Result: AccountModel;
}