import { DiscountCodeModel } from './DiscountCodeModel';

export class DiscountCodeModelDataResponse {
    isSuccess: boolean;
    affectedRecords: number;
    endUserMessage: string;
    validationErrors: [{
                        Name: string;
                        Description: string;
                      }];
    result: DiscountCodeModel;
}