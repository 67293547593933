import {ValidationItemModel} from './index';

export enum ChangePasswordResult {
  Failed = 'Failed',
  Succeeded = 'Succeeded',
  BadOldPassword = 'BadOldPassword',
  BadNewPassword = 'BadNewPassword',
  ConfirmPasswordMismatch = 'ConfirmPasswordMismatch',
  WeakPassword = 'WeakPassword',
  CannotReuseOldPassword = 'CannotReuseOldPassword',
  BadResetCode = 'BadResetCode'
}

export class ChangePasswordResultModel {
    Result: ChangePasswordResult;
    isSuccess: boolean;
    affectedRecords: number;
    endUserMessage: string;
    validationErrors:  ValidationItemModel [];
}
