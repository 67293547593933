import { AccountModel, UserPreferenceModel } from './';

export class UserModel {
    public id: string;
    public clientId: string;
    public firstname: string;
    public username: string;
    public lastname: string;
    public mobile: string;
    public email: string;
    public password: string;
    public passwordConfirmation: string;
    public mobileVerificationCode: string;
    public roles: string [];
    public createdOn: Date;
    public lastPortalLogin: Date;
    public lastBroadbandLogin: Date;
    public account: AccountModel;
    public sales: any;
    public summaryVoucherSales: any;
    public dealerSalesSummary: any;
    public unifiedSales: any;
    public emailVerified: boolean;

    public LastLoginDate: Date;
    public LastRefillMethod: number;
    public LoginHistory: any [];
    public OverallSales: any;
    public ProductInventory: any;
    public OverallRefills: any;
    public Refills: any;
    public UserPurchases: any;
    public userPreference: UserPreferenceModel;
}

