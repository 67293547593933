import { from } from "rxjs";
import {ValidationItemModel} from './index';

export class BooleanValueDataResponseModel {
    result: boolean;
    isSuccess: boolean;
    affectedRecords: number;
    endUserMessage: string;
    validationErrors: ValidationItemModel [];
}
