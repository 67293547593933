export class CarouselSlideModel {
    public Id: string;
    public Category: string;
    public ImageUrl: string;
    public Title: string;
    public Synopsis: string;
    public Link: string;
    public Likes: number;
    public LikedByUser: boolean;
    public Dislikes: number;
    public PostedOn: Date;
}
