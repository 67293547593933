export class AuthenticationResultDataResponse {
    isSuccess: boolean;
    result: AuthenticationResult;
}

export class AuthenticationResult {
    token: string;
    refreshToken: string;
    token_type: string;
    expires_in: Date;
    error: string;
    error_description: string;
    keepMeSignedIn: boolean;
    issuedUtc: Date;
    expiresUtc: Date;
}
