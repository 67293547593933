import { AccountModel } from './AccountModel';
import { CarouselSlideModel } from './CarouselSlideModel';
import { UserModel } from './UserModel';

export class UserDataResponseModel {
    isSuccess: boolean;
    affectedRecords: number;
    endUserMessage: string;
    validationErrors: [{
                        Name: string;
                        Description: string;
                      }];
    result: UserModel;
}

