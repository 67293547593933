import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import 'rxjs/add/observable/fromEvent';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/distinctUntilChanged';
import { fromEvent } from 'rxjs';
import { startWith, map, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class WindowWidthService {
  private minWidthBreakpoint: number;

  public onResize(minWidthBreakpoint: number, scrollBar?: boolean): Observable<boolean | {}> {
    this.minWidthBreakpoint = minWidthBreakpoint;

    return fromEvent(window, 'resize')
      .pipe(map(() => this.assertSize(scrollBar)))
      .pipe(startWith(this.assertSize(scrollBar)))
      .pipe(distinctUntilChanged());
  };

  private assertSize(scrollBar?: boolean): boolean {
    const area = scrollBar ? window.innerWidth : document.documentElement.clientWidth;

    return this.minWidthBreakpoint <= area;
  }
}
