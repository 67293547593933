import { Injectable } from '@angular/core';
import { HttpClient,
         HttpHeaders,
         HttpParams } from '@angular/common/http';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { AutoLogoutService } from './autologout.service';
import { EncryptionService } from './encryption.service';
import { BaoConstants } from '../enums/index';

import { BooleanValueDataResponseModel,
         DataResponseModel,
         PageDataResponseModel,
         UserModel,
         RegisterUserModel,
         UserDataResponseModel,
         CarouselSlideModel,
         ListDataResponseModel,
         ResetPasswordModel,
         ChangePasswordResultModel,
         VerifyEmailResultModel,
         SubmitFeedbackModel,
         SearchUserDataResponseModel,
         ChangePasswordModel, 
         UserPreferenceModel} from '../models';
import { retryRequest } from '../pipes/retry-request.operator';

@Injectable()
export class UserService {

    private authUrl: string =  '/Authentication';
    private actionUrl: string =  '/User';
    private headers: HttpHeaders;
    private httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      };

    private autoLogout: AutoLogoutService;

    constructor(private http: HttpClient,
                private authenticationService: AuthenticationService,
                private encryptService: EncryptionService) {

        this.autoLogout = new AutoLogoutService(authenticationService);
    }

    // "/api/User/LoadUser/:loadUserDetails"
    public GetCurrentUser(): Observable<any> {

        return this.http.get( `${this.actionUrl}/GetUserProfile`,
                               this.httpOptions)
                        .pipe(retryRequest());
    }

    public GetLoggedInUser(): UserModel {
        const user = localStorage.getItem(BaoConstants.USER_MODEL);

        if (null == user || '' === user) {
            return null;
        }

        const userModel = JSON.parse(user);
        return userModel;
    }

    // "/api/User/GetUserById/:userId"
    public GetUserById(userId: string): Observable<UserDataResponseModel> {

       return this.http.get<UserDataResponseModel>( `${this.actionUrl}/GetUserProfileById/${userId}/`,
                                                    this.httpOptions)
                        .pipe(retryRequest());
    }

    // "/api/User/FindUserByEmail/:emailAddress"
    public GetUser(username: string): Observable<UserDataResponseModel> {

       return this.http.get<UserDataResponseModel>( `${this.actionUrl}/FindUserByUsername/${username}/`,
                                                    this.httpOptions)
                        .pipe(retryRequest());
    }

    // "/api/User/CreateUser"
    public RegisterNewUser(newUser: RegisterUserModel): Observable<DataResponseModel> {

        const options = {
            headers: new HttpHeaders({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-Skip-Interceptor': ''
            })
          };

        return this.http.post<DataResponseModel>(  `${this.actionUrl}/RegisterUser`,
                                                    newUser,
                                                    options
                                                );
    }

     // "/api/User/SendVerificationCode"
     public SendVerificationCode(mobileNumber: string): Observable<DataResponseModel> {

        const options = {
            headers: new HttpHeaders({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-Skip-Interceptor': ''
            })
          };

        return this.http.get<any>( `${this.actionUrl}/SendMobileVerificationCode/${mobileNumber}/`,
                                    options)
                        .pipe(retryRequest());
    }

    // "/api/User/SendEmailVerificationLink"
    public SendEmailVerificationLink(emailAddress: string): Observable<DataResponseModel> {

        const options = {
            headers: new HttpHeaders({
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            })
          };

        return this.http.get<any>( `${this.actionUrl}/SendEmailVerificationLink/${emailAddress}/`,
                                                 options
                                                )
                        .pipe(retryRequest());
    }

    // "/api/User/ValidateMobileNumber"
    public ValidateMobileNumber(mobileNumber: string, verificationCode: string): Observable<BooleanValueDataResponseModel> {

        const options = {
            headers: new HttpHeaders({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-Skip-Interceptor': ''
            })
          };

        return this.http.get<BooleanValueDataResponseModel>( this.actionUrl +
                                                             `/ValidateMobileNumber/${mobileNumber}/${verificationCode}/`,
                                                             options)
                        .pipe(retryRequest());
    }

    // "/api/User/ChangeLikeStatus"
    public ChangeLikeStatus(slide: CarouselSlideModel) {

        this.http.post<DataResponseModel>(  `${this.actionUrl}/ChangeLikeStatus`,
                                            slide,
                                            this.httpOptions
                                         )
                                         .pipe(retryRequest())
                                         .subscribe();
    }

    public ListUsers(pageNumber: Number, pageSize: Number): Observable<PageDataResponseModel> {

        return this.http.get<PageDataResponseModel>( `${this.actionUrl}/ListUsers/${pageNumber}/${pageSize}`,
                                                     this.httpOptions)
                        .pipe(retryRequest());
    }

    public SearchUsers(searchTerm: string): Observable<ListDataResponseModel> {

        return this.http.get<ListDataResponseModel>(`${this.authUrl}/SearchUsers/${searchTerm}/`,
                                                     this.httpOptions)
                        .pipe(retryRequest());
    }

    public RetrieveUser(searchTerm: string): Observable<SearchUserDataResponseModel> {

        return this.http.get<SearchUserDataResponseModel>(`${this.authUrl}/RetrieveUser/${searchTerm}/`,
                                                        this.httpOptions
                                                    )
                        .pipe(retryRequest());
    }

    public ChangePassword(oldPassword: string, password: string, passwordConfirmation: string): Observable<ChangePasswordResultModel> {

        const dto: ChangePasswordModel = new ChangePasswordModel();
        dto.oldPassword = oldPassword;
        dto.newPassword = password;
        dto.passwordConfirmation = passwordConfirmation;

        return this.http.post<ChangePasswordResultModel>(`${this.actionUrl}/ChangePassword`,
                                                            dto,
                                                            this.httpOptions
                                                        )
                        .pipe(retryRequest());
    }

    public ResetPassword(resetCode: string, password: string, passwordConfirmation: string): Observable<ChangePasswordResultModel> {

        const dto: ResetPasswordModel = new ResetPasswordModel();
        dto.ResetCode = resetCode;
        dto.Password = password;
        dto.PasswordConfirmation = passwordConfirmation;

        return this.http.post<ChangePasswordResultModel>(   `${this.authUrl}/ResetPassword`,
                                                            dto,
                                                            this.httpOptions
                                                        )
                        .pipe(retryRequest());
    }

    public EmailResetLink(userId: string): Observable<DataResponseModel> {

        return this.http.get<DataResponseModel>(`${this.authUrl}/EmailPasswordResetLink/${userId}/`,
                                                        this.httpOptions
                                                    )
                        .pipe(retryRequest());
    }

    public TextResetCode(userId: string): Observable<DataResponseModel> {

        let params =  new HttpParams();
        params = params.append('userId', userId);

        return this.http.get<DataResponseModel>(`${this.authUrl}/TextPasswordResetCode/${userId}/`,
                                                        this.httpOptions
                                                    )
                        .pipe(retryRequest());
    }

    public VerifyEmail(email: string, verificationCode: string): Observable<VerifyEmailResultModel> {

        return this.http.get<VerifyEmailResultModel>(  `${this.actionUrl}/ValidateEmailAddress/${email}/${verificationCode}/`,
                                                        this.httpOptions
                                                    )
                        .pipe(retryRequest());
    }

    public SubmitFeedback(fullName: string, emailAddress: string, mobileNumber: string, message: string) {

        const dto: SubmitFeedbackModel = new SubmitFeedbackModel();
        dto.Fullname = fullName;
        dto.EmailAddress = emailAddress;
        dto.MobileNumber = mobileNumber;
        dto.Message = message;

        return this.http.post<ChangePasswordResultModel>(   `${this.actionUrl}/SubmitFeedback`,
                                                            dto,
                                                            this.httpOptions
                                                        )
                        .pipe(retryRequest());
    }

    public GetDashboardUrl(): string {
        let dashboardUrl: string = '/app/dashboard';

        if (this.authenticationService.HasRole('administrator')) {
            dashboardUrl = '/app/dashboard/admin';
        } else if (this.authenticationService.HasRole('manager')) {
            dashboardUrl = '/app/dashboard/manager';
        } 
        else if (this.authenticationService.HasRole('dealer')) {
            dashboardUrl = '/app/dashboard/dealer';
        }

        return dashboardUrl;
    }

    public GetDashboardPath(): string {
        let dashboard: string = 'dashboard';

        if (true === this.authenticationService.HasRole('Dealer')) {
            dashboard = 'dashboard/dealer';
        }
        else if (true === this.authenticationService.HasRole('Manager')) {
            dashboard = 'dashboard/manager';
        }
        else if (true === this.authenticationService.HasRole('Administrator')) {
            dashboard = 'dashboard/administrator';
        }

        return dashboard;
    }

    public isDealer(): boolean {

        if (this.authenticationService.HasRole('Dealer')){
            return true;
        }
        return false;
    }

    public isManager(): boolean {

        if (this.authenticationService.HasRole('Manager')){
            return true;
        }
        return false;
    }
  
    public isAdministrator(): boolean {

        if (this.authenticationService.HasRole('Administrator')){
            return true;
        }
        return false;
    }

    public SaveUserPreference(userPreference: UserPreferenceModel): Observable<DataResponseModel> {
        const options = {
            headers: new HttpHeaders({
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            })
          };

        return this.http.post<DataResponseModel>(  `${this.actionUrl}/SaveUserPreferences`,
                                                    userPreference,
                                                    options
                                                );
    }
}
