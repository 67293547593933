import { Injectable } from '@angular/core';
import { HttpClient,
        HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ValueDataResponseModel, DataResponseModel, GSMPortModel, UpdateGSMPortModel } from '../models';
import { retryRequest } from '../pipes/retry-request.operator';

import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';

@Injectable()
export class AdministrationService {

    private actionUrl: string = '/Administration';
    private headers: HttpHeaders;
    private httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      };

    constructor(private http: HttpClient) {

        this.headers = new HttpHeaders();
        this.headers.append('Accept', 'application/json');
        this.headers.append('Content-Type', 'application/json');
    }

    public ListGSMPorts(): Observable<ValueDataResponseModel> {

       return this.http.get<ValueDataResponseModel>( `${this.actionUrl}/ListGSMPorts/`,
                                                this.httpOptions
                                              )
                        .pipe(retryRequest());
    }

    public UpdateGSMPort( id: string,
                                  isActive: string,
                                  prefixes: string []
                                ): Observable<DataResponseModel> {

      const dto: UpdateGSMPortModel = {
        'id': id,
        'isActive': isActive,
        'prefixes': prefixes,
      };

      return this.http.post<DataResponseModel>( `${this.actionUrl}/UpdateGSMPort/`,
                                                dto,
                                                this.httpOptions
                                              )
                      .pipe(retryRequest());

    }     
}
