import { Routes } from '@angular/router';
import { ErrorComponent } from './pages/error/error.component';
import { AuthGuard } from './services';

export const ROUTES: Routes = [{
   path: '', redirectTo: 'app', pathMatch: 'full'
  },
  {
    path: 'welcome', loadChildren: () => import('./default/welcome.module').then(m => m.WelcomeModule)
  },
  {
    path: 'buynow', redirectTo: 'welcome/buynow', pathMatch: 'full'
  },
  {
    path: 'buy', redirectTo: 'welcome/buynow', pathMatch: 'full'
  },
  {
    path: 'user', loadChildren: () => import('./user-area/user.module').then(m => m.UserModule)
  },
  {
    path: 'login', redirectTo: 'user/login', pathMatch: 'full'
  },
  {
    path: 'reset', redirectTo: 'user/reset', pathMatch: 'full'
  },
  {
    path: 'register', redirectTo: 'user/register', pathMatch: 'full'
  },
  {
    path: 'administration', loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule), canActivate: [AuthGuard]
  },
  {
    path: 'app',   loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard]
  },
  {
    path: 'error', component: ErrorComponent
  },
  {
    path: '**',    component: ErrorComponent
  }
];
