export class ProductDetailsModel {

    public quantity: number;
    public status: string;
    public id: string;
    public name: string;
    public displayName: string;
    public shortDescription: string;
    public description: string;
    public price: number;
    public cssBackgroundClass: string;

    constructor(item) {
       // this.quantity = item.quantity;
        this.status = item.status.description;
        this.id = item.details.id;
        this.name = item.details.name;
        this.displayName = item.details.displayName;
       // this.shortDescription = item.details.shortDescription;
        this.shortDescription = item.details.description;
        this.price = item.details.price;
        this.cssBackgroundClass = item.details.cssBackgroundClass;
    }

    static init(): ProductDetailsModel {
        const product: ProductDetailsModel = {
            id: '',
            status : '',
            name: '',
            displayName: '',
            shortDescription: '',
            price: 0,
            cssBackgroundClass: '',
            quantity: 0,
            description: ''
        };

        return product;
    }
}
