import {Injectable, Injector} from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/do';
import { environment } from '../../environments/environment';

export const InterceptorSkipHeader: string = 'X-Skip-Interceptor';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private request: string =  environment.baoHotspotCoreApiURL;

  constructor(private injector: Injector, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({url: this.request + request.url});

    //
    // skip appending authorization header
    //
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    //
    // pull the authorization header from authentication service
    //
    const authenticationService = this.injector.get(AuthenticationService);
    const jwt = authenticationService.GetJwt();

    if (null == jwt || '' === jwt) {
      console.log('getjwt() - missing token');
    }

    //
    // append the authorization header to request
    //
    const JWT = `Bearer ${jwt}`;

    request = request.clone({
        setHeaders: { Authorization: JWT }
    });

    //
    // handle the next chain
    //
    return next.handle(request);
  }
}
