export class UserPreferenceModel {
    public autoConvertToPackage: boolean;
    public autoRechargeAccount: boolean;
    public lowBalanceNotification: boolean;
    public lowBalanceThreshold: number;
    public voucherExpirationNotification: boolean;
    public voucherExpirationThreshold: number;
    public allowNegativeBalance: boolean;
    public enableEmailNotifications: boolean;
    public enableSMSNotifications: boolean;
}
