export const environment = {
  production: false,
  hmr: false,
  backend: false,
  dev: true,
  authorizationApiURL: 'https://devauth.bao.co.tz/',
  baoHotspotCoreApiURL: 'https://devhotspotapi.bao.co.tz/api',
  captivePortalURL: 'https://surf.bao.co.tz/login',
  baseURL: 'https://dev.bao.co.tz',
  clientId: '423279f3-099b-4e1c-af25-b3a9239d1f8a'
};
