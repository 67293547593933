import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()

export class EncryptionService {

    private salt: any  = CryptoJS.enc.Utf8.parse('1ed6bef9fd72406b');
    private key = CryptoJS.enc.Utf8.parse('b36736d9810cf998');

    constructor() {}

    public encrypt(message): string {
        //
        // encrypt the Password with Base64
        //
        const key = CryptoJS.enc.Utf8.parse('b36736d9810cf998');

        const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(message),
                                           this.key,
                                           {
                                               keySize: 128 / 8,
                                               iv: this.salt,
                                               mode: CryptoJS.mode.CBC,
                                               padding: CryptoJS.pad.Pkcs7
                                           });

        return this.base64EncodeUrl(encrypted.toString());
    }

    public encryptWithSalt(message, customSalt): string {

        //
        // encrypt the Password with Base64
        //
        const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(message),
                                               this.key,
                                               {
                                                   keySize: 128 / 8,
                                                   iv: customSalt,
                                                   mode: CryptoJS.mode.CBC,
                                                   padding: CryptoJS.pad.Pkcs7
                                               });

        return this.base64EncodeUrl(encrypted.toString());
    }

    ///
    /// use this to make a Base64 encoded string URL friendly,
    /// i.e. '+' and '/' are replaced with '-' and '_' also any trailing '='
    /// characters are removed
    ///
    /// @param {String} str the encoded string
    /// @returns {String} the URL friendly encoded String
    ///
    private base64EncodeUrl(str) {
        return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
    }
}
