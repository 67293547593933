export class NumberValueDataResponseModel {
    Result: number;
    isSuccess: boolean;
    affectedRecords: number;
    endUserMessage: string;
    validationErrors: [{
                        Name: string;
                        Description: string;
                      }];
}
