export class UserRole {
    public Id: string;
    public ClientId: string;
    public UserId: string;
    public Username: string;
    public Name: string;
    public RoleId: string;
    public Description: string;
    public CreateDate: Date;
    public CrateddBy: Date;

    constructor(username: string,
                name: any,
                clientId: string) {

    this.Username = username;
    this.Name = name;
    this.ClientId = clientId;
    }
}
