import { Injectable } from '@angular/core';
import { HttpClient,
         HttpHeaders,
         HttpParams } from '@angular/common/http';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import {  DataResponseModel,
          VoucherModel,
          ListDataResponseModel,
          UserModel,
          DiscountCodeModelDataResponse,
          TransactionModelDataResponse,
          BuyNowModel} from '../models';
import { retryRequest } from '../pipes/retry-request.operator';

@Injectable()
export class VoucherService {

    private vouchersUrl: string =  '/Voucher';
    private productsUrl: string =  '/Products';
    private mobileWalletUrl: string = '/MobileWallet';
    private headers: HttpHeaders;
    private httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      };

    constructor(private http: HttpClient) {

        this.headers = new HttpHeaders();
        this.headers.append('Accept', 'application/json');
        this.headers.append('Content-Type', 'application/json');
    }


    public SellVoucher(userModel: UserModel, baoPackage: string): Observable<TransactionModelDataResponse> {

       return this.http.get<TransactionModelDataResponse>(`${this.vouchersUrl}/SellVoucher/` 
                                                            + `${userModel.firstname}/${userModel.lastname}/`
                                                            + `${userModel.mobile}/${userModel.email}/${baoPackage}/`
                                                            + `${userModel.id}/`,
                                                            this.httpOptions);
    }

    public PurchaseVoucher(baoPackage: string, paymentMethod: string, discountCode: string): Observable<TransactionModelDataResponse> {

        if (null === discountCode || '' === discountCode) {
            discountCode = 'none';
        }

        return this.http.get<TransactionModelDataResponse>( `${this.vouchersUrl}/PurchaseVoucher/${baoPackage}/`
                                                          + `${paymentMethod}/${discountCode}`,
                                                            this.httpOptions
                                                           );
    }

    public Buynow(purchase: BuyNowModel): Observable<any> {

      return this.http.post<DataResponseModel>( `${this.mobileWalletUrl}/StartBuyNowPurchase`,
                                                purchase,
                                                this.httpOptions
                                              );
    }

    public UploadVouchers(vouchers: Array<VoucherModel>): Observable<DataResponseModel> {

        return this.http.post<DataResponseModel>( `${this.vouchersUrl}/UploadVouchers`,
                                                  vouchers,
                                                  this.httpOptions
                                                )
                        .pipe(retryRequest());
    }

    public ListVouchers(pageNumber: Number, pageSize: Number): Observable<any> {

        return this.http.get<ListDataResponseModel>(`${this.vouchersUrl}/ListVouchers/${pageNumber}/${pageSize}`,
                                                    this.httpOptions
                                                    )
                        .pipe(retryRequest());
    }

    public FindDiscountCode(discountCode: string): Observable<DiscountCodeModelDataResponse> {

        return this.http.get<DiscountCodeModelDataResponse>(`${this.vouchersUrl}/GetDiscountCode/${discountCode}/`,
                                                              this.httpOptions
                                                          );
    }

    // "/api/Voucher/ListProducts"
    public ListProducts(): Observable<any> {

        const options = {
            headers: new HttpHeaders({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-Skip-Interceptor': ''
            })
          };

        return this.http.get( `${this.productsUrl}/ListProducts`,
                               options
                            )
                        .pipe(retryRequest());
    }

    public ResendVoucher(transactionId: string): Observable<DataResponseModel> {

      return this.http.get<DataResponseModel>(`${this.vouchersUrl}/ResendVoucher/${transactionId}/`,
                                                    this.httpOptions
                                                );
  }
}
