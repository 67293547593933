export class GSMPortModel {
    id: string;
    host: string;
    username: string;
    password: string;
    location: string;
    port: string;
    mobile: string;
    provider: string;
    prefixes: string [];
    longPrefixes: string [];
    isActive: boolean;
    isDeleted: boolean;
    priority: number;
    createdOn: Date;
    createdBy : string;
}
